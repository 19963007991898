import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

ReactGA.initialize("UA-174563742-1");

const history = createBrowserHistory();

// 초기화
history.listen((location) => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const app = (
	<BrowserRouter history={history}>
		<App />
	</BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
// serviceWorker.unregister();
